import React, { useState, useCallback, useEffect } from "react";
import { useLoading } from "../../hooks/useLoading";
import { useNotification } from "../../hooks/useNotification";
import axios from "../../services/instance";
import {
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  Stack,
} from "@mui/material";

const ALERT_STYLE = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 9999,
};

const INITIAL_SICK_LEAVE_STATE = {
  start: "",
  end: "",
  comment: "",
};

const SickLeaveForm = () => {
  const { toggleLoading } = useLoading();
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useNotification();
  const [sickLeave, setSickLeave] = useState(INITIAL_SICK_LEAVE_STATE);
  const [showSuccess, setShowSuccess] = useState(false);

  const { start, end, comment } = sickLeave;

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setSickLeave((prev) => ({ ...prev, [name]: value }));
  }, []);

  useEffect(() => {
    const validateDate = () => {
      const error =
        start > end ? "Дата конца должна быть позже даты начала" : null;
      setErrors((prev) => ({ ...prev, end: error }));
    };
    validateDate();
  }, [start, end]);

  const hasErrors = Object.values(errors).some(Boolean);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (hasErrors) return;

      toggleLoading();
      try {
        await axios.post("/sick_leaves", sickLeave);
        setSickLeave(INITIAL_SICK_LEAVE_STATE); // Reset form
        setShowSuccess(true); // Show success message
        setErrors({}); // Clear any existing errors
      } catch (error) {
        setNotification({
          message: "Failed to submit sick leave request.",
          type: "error",
        });
        console.error(error);
      } finally {
        toggleLoading();
      }
    },
    [sickLeave, hasErrors, toggleLoading]
  );

  const handleSuccessClose = useCallback(() => {
    setShowSuccess(false);
  }, []);

  return (
    <Paper sx={{ minHeight: 'max-content', p: 2 }}>
      {showSuccess && (
        <Alert severity="success" onClose={handleSuccessClose} sx={ALERT_STYLE}>
          Sick leave request submitted successfully!
        </Alert>
      )}
      <Stack spacing={2}>
        <Typography variant="h6">Sick Leave Request</Typography>
        <Box>
          <Typography>Вы можете заполнить форму как для краткосрочного больничного, так и для долгосрочного.</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Дата начала больничного"
              type="date"
              name="start"
              value={start}
              onChange={handleInputChange}
              error={!!errors.start}
              helperText={errors.start}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Дата завершения больничного"
              type="date"
              name="end"
              value={end}
              onChange={handleInputChange}
              error={!!errors.end}
              helperText={errors.end}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Комментарий"
              name="comment"
              value={comment}
              onChange={handleInputChange}
              multiline
              rows={3}
              placeholder="Дополнительная информация о больничном (необязательно)"
            />
            <Button type="submit" variant="contained" color="primary">
              Отправить запрос
            </Button>
          </Stack>
        </form>
      </Stack>
      {notification}
    </Paper>
  );
};

export default SickLeaveForm;
