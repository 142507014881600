import "./App.css";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Admin from "./components/Admin/Admin";
import TaskList from "./components/TaskList/TaskList";
import GroupList from "./components/GroupList/GroupList";
import HomeworkList from "./components/HomeworkList/HomeworkList";
import NoMatch from "./components/NoMatch/NoMatch";
import NoPermission from "./components/NoPermission/NoPermission";
import MessageSender from "./components/MessageSender/MessageSender";
import Leaderboard from "./components/Leaderboard/Leaderboard";
import LoadingModal from "./components/LoadingModal/LoadingModal";
import VacationForm from "./components/VacationForm/VacationForm";
import SickLeaveForm from "./components/SickLeaveForm/SickLeaveForm";
import { useTelegram } from "./hooks/useTelegram";
import { useLoading } from "./hooks/useLoading";
import { useAuth } from "./hooks/useAuth";

function App() {
  const { isSignedIn, isAdmin } = useAuth();
  const { loading } = useLoading();
  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
    tg.expand();
  }, [tg]);

  return (
    <div className="App">
      <LoadingModal />
      <Header />
      {isSignedIn && (
        <Routes>
          <Route index element={<div>This is index</div>} />
          <Route path="tasks" element={<TaskList />} />
          <Route path="groups" element={<GroupList />} />
          <Route path="leaderboard" element={<Leaderboard />} />
          <Route path="vacation" element={<VacationForm />} />
          <Route path="sick-leave" element={<SickLeaveForm />} />
          {isAdmin && (
            <Route path="private" element={<Admin/>}>
              <Route path="notifications" element={<MessageSender />} />
              <Route path="homeworks" element={<HomeworkList />} />
            </Route>
          )}
          <Route path="*" element={<NoMatch />} />
        </Routes>
      )}
      {!isSignedIn && !loading && <NoPermission />}
    </div>
  );
}

export default App;
